@import 'normalize.css/normalize.css';
@import 'typeface-lato/index.css';
@import './mixins.scss';

.flex {
    display: flex;
}

.body {
    background-color: white;
    justify-content: center;
    font-family: "Lato";
    flex-direction: column;
    max-width: 100%;

    height: 200px;
    max-height: 100vh;

    .content {
        margin-top: 2em;
        flex-direction: column;

        .title {
            justify-content: center;

            h1 {
                margin: 0;
                font-size: 8em;
                font-weight: 300;
                @include textGlitch("title", 20, black, white, rgb(139, 139, 139), white);
            }
        }

        .sub-title {
            justify-content: center;
        }

        .sub-comment {
            justify-content: center;
            margin-top: 0.5em;
        }
    }

    .footer {
        justify-content: center;

        .contact {
            justify-content: center;
            margin-top: 0.5em;

            a {
                color: #aaaaaa;
            }

            .hide {
               visibility: hidden;
            }
        }
    }
}
