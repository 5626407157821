/*
  (TEXT) PARAMS
  =================
  1. Namespace
  2. Intensity
  3. Text color
  4. Background color (flat)
  5. Highlight #1 color
  6. Highlight #2 color
*/

@mixin textGlitch($name, $intensity, $textColor, $background, $highlightColor1, $highlightColor2) {

    // https://css-tricks.com/glitch-effect-text-images-svg/

    color: $textColor;
    position: relative;
    $steps: $intensity;

    // Ensure the @keyframes are generated at the root level
    @at-root {

        // We need two different ones
        @for $i from 1 through 2 {
            @keyframes #{$name}-anim-#{$i} {
                @for $i from 0 through $steps {
                    $top: random(100);
                    $topPercent: $top * 1%;
                    $bottom: random(101 - $top);
                    $bottomPercent: $bottom * 1%;

                    #{percentage($i*(1/$steps))} {
                        clip-path: inset(#{$topPercent} 0 #{$bottomPercent} 0);
                    }
                }
            }
        }
    }

    &::before,
    &::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: $background;
    }

    &::after {
        left: 2px;
        text-shadow: -1px 0 $highlightColor1;
        animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
    }

    &::before {
        left: -2px;
        text-shadow: 2px 0 $highlightColor2;
        animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
    }
}
